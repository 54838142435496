<template>
  <table
    v-if="
      isRouterDiff ||
      isDeviceSettings ||
      isCameraDiff ||
      isProjectDiff ||
      isOfflineMessage ||
      isNvrDeviceSettings ||
      isUserDiff ||
      isDeleteSettings ||
      isROISettings ||
      isLayerDiff
    "
    class="simple-table w-100"
  >
    <thead
      v-if="
        isRouterDiff ||
        isCameraDiff ||
        isProjectDiff ||
        isUserDiff ||
        isROISettings ||
        isLayerDiff
      "
    >
      <tr>
        <th width="278px" class="caption font-weight-medium">Settings</th>
        <th width="230px" class="caption font-weight-medium">Old</th>
        <th class="caption font-weight-medium">New</th>
      </tr>
    </thead>

    <thead v-else-if="isOfflineMessage">
      <tr>
        <th class="caption font-weight-medium">Offline Body</th>
      </tr>
    </thead>

    <thead v-else-if="isNvrDeviceSettings || isDeleteSettings">
      <tr>
        <th width="278px" class="caption font-weight-medium">Name</th>
        <th width="230px" class="caption font-weight-medium">Value</th>
      </tr>
    </thead>

    <thead v-else>
      <tr>
        <th width="278px" class="caption font-weight-medium">Name</th>
        <th width="230px" class="caption font-weight-medium">Value</th>
      </tr>
    </thead>

    <tbody v-if="isCameraDiff">
      <tr
        v-for="(value, key) in cameraDiff.old"
        v-show="hasChanged(cameraDiff, key)"
        :key="key"
      >
        <td>{{ CAMERA_SETTINGS[key] || key }}</td>
        <td>{{ cameraDiff.old[key] }}</td>
        <td>{{ cameraDiff.new[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isLayerDiff">
      <tr
        v-for="(value, key) in layerDiff.old"
        v-show="hasChanged(layerDiff, key)"
        :key="key"
      >
        <td>{{ LAYER_SETTINGS[key] || key }}</td>
        <td>{{ layerDiff.old[key] }}</td>
        <td>{{ layerDiff.new[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isUserDiff">
      <tr
        v-for="(value, key) in userDiff.old"
        v-show="hasChanged(userDiff, key)"
        :key="key"
      >
        <td>{{ key }}</td>
        <td>{{ userDiff.old[key] }}</td>
        <td>{{ userDiff.new[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isRouterDiff">
      <tr
        v-for="(value, key) in routerDiff.old"
        v-show="hasChanged(routerDiff, key)"
        :key="key"
      >
        <td>{{ ROUTER_SETTINGS[key] || key }}</td>
        <td>{{ routerDiff.old[key] }}</td>
        <td>{{ routerDiff.new[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isProjectDiff">
      <tr
        v-for="(value, key) in projectDiff.old"
        v-show="hasChanged(projectDiff, key)"
        :key="key"
      >
        <td>{{ PROJECT_SETTINGS[key] || key }}</td>
        <td>{{ projectDiff.old[key] }}</td>
        <td>{{ projectDiff.new[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isOfflineMessage">
      <tr>
        <td>{{ offlineBody }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isDeleteSettings">
      <tr v-for="(setting, key) in deleteSettings" :key="key">
        <td>{{ key }}</td>
        <td>{{ deleteSettings[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isNvrDeviceSettings">
      <tr v-for="(setting, key) in NVR_SETTINGS" :key="key">
        <td>{{ NVR_SETTINGS[key] }}</td>
        <td>{{ nvrSettings[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isROISettings">
      <tr
        v-for="(setting, key) in ROI_SETTINGS"
        v-show="hasChanged(roiDiff, key)"
        :key="key"
        class="white"
      >
        <td>{{ setting }}</td>
        <td>
          <pre v-if="typeof roiDiff.old[key] === 'object'">{{
            JSON.stringify(roiDiff.old[key], null, 2)
          }}</pre>
          <span v-else>
            {{ roiDiff.old[key] }}
          </span>
        </td>
        <td>
          <pre v-if="typeof roiDiff.new[key] === 'object'">{{
            JSON.stringify(roiDiff.new[key], null, 2)
          }}</pre>
          <span v-else>
            {{ roiDiff.new[key] }}
          </span>
        </td>
      </tr>
    </tbody>

    <tbody v-else>
      <tr v-for="(setting, key) in ROUTER_DEVICE_SETTINGS" :key="key">
        <td>{{ ROUTER_DEVICE_SETTINGS[key] }}</td>
        <td>{{ deviceSetings[key] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {
  CAMERA_SETTINGS,
  ROUTER_SETTINGS,
  ROUTER_DEVICE_SETTINGS,
  PROJECT_SETTINGS,
  ROI_SETTINGS,
  NVR_SETTINGS,
  LAYER_SETTINGS,
} from "@/components/constants.js"
import LOG_TYPES from "@evercam/shared/constants/logTypes"

export default {
  name: "CameraLogDiff",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cameraDiff() {
      return this.item.details?.camera?.settings || this.item.details?.settings
    },
    layerDiff() {
      return this.item.details?.settings || {}
    },
    roiDiff() {
      return this.item.details?.settings || {}
    },
    routerDiff() {
      return this.item.details?.router?.settings
    },
    projectDiff() {
      return this.item.details?.project?.settings
    },
    userDiff() {
      return this.item.details?.user?.settings
    },
    deviceSetings() {
      return this.item.details?.router?.deviceSettings || {}
    },
    offlineBody() {
      return this.item.details?.camera?.errorBody || {}
    },
    nvrSettings() {
      let hddInfo = {
        hddName: "",
        hddCapacity: "",
        freeSpace: "",
        hddStatus: "",
        hddProperty: "",
      }
      if (Object.keys(this.item.details?.hddInfo).length > 0) {
        this.item.details?.hddInfo.map((hdd, index) => {
          let textSeparator = index === 0 ? "" : "/"
          hddInfo.hddName += `${textSeparator}${hdd.name}`
          hddInfo.hddCapacity += `${textSeparator}${hdd.capacity}`
          hddInfo.freeSpace += `${textSeparator}${hdd.freeSpace}`
          hddInfo.hddStatus += `${textSeparator}${hdd.status}`
          hddInfo.hddProperty += `${textSeparator}${hdd.property}`
        })
      }

      return {
        ...this.item.details,
        ...hddInfo,
      }
    },
    deleteSettings() {
      return (
        this.item.details?.user ||
        this.item.details?.sim ||
        this.item.details?.camera ||
        this.item.details?.project ||
        this.item.details
      )
    },
    isDeleteSettings() {
      return (
        this.item.action === LOG_TYPES.DELETE &&
        (this.item.details?.user != undefined ||
          this.item.details?.camera != undefined ||
          this.item.details?.project != undefined ||
          this.item.details?.config != undefined ||
          this.item.details?.sim != undefined)
      )
    },
    isRouterDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        this.item.details?.router?.settings != undefined
      )
    },
    isCameraDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        (this.item.details?.camera?.settings != undefined ||
          this.item.details?.settings != undefined) &&
        this.item.entity === "cameras"
      )
    },
    isLayerDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        this.item.details?.settings != undefined &&
        this.item.entity === "layers"
      )
    },
    isProjectDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        this.item.details?.project?.settings != undefined
      )
    },
    isUserDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        this.item.details?.user?.settings != undefined
      )
    },
    isDeviceSettings() {
      return (
        this.item.action === LOG_TYPES.DEVICE_SETTINGS &&
        this.item.details?.router?.deviceSettings != undefined
      )
    },
    isNvrDeviceSettings() {
      return (
        this.item.action === LOG_TYPES.DEVICE_SETTINGS &&
        this.item.entity === "nvr"
      )
    },
    isOfflineMessage() {
      return (
        this.item.action === LOG_TYPES.OFFLINE &&
        this.item.details?.camera?.errorBody != undefined
      )
    },
    isROISettings() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        !!this.item.details?.settings &&
        this.item.entity === "rois"
      )
    },
  },
  created() {
    this.ROUTER_SETTINGS = ROUTER_SETTINGS
    this.ROUTER_DEVICE_SETTINGS = ROUTER_DEVICE_SETTINGS
    this.CAMERA_SETTINGS = CAMERA_SETTINGS
    this.PROJECT_SETTINGS = PROJECT_SETTINGS
    this.NVR_SETTINGS = NVR_SETTINGS
    this.ROI_SETTINGS = ROI_SETTINGS
    this.LAYER_SETTINGS = LAYER_SETTINGS
  },
  methods: {
    hasChanged(diff, key) {
      return JSON.stringify(diff.old[key]) !== JSON.stringify(diff.new[key])
    },
  },
}
</script>

<style lang="scss">
.simple-table {
  border-collapse: collapse;
  th {
    text-align: left;
  }
}
</style>
